'use client';
import { useEffect, useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { useParams, usePathname } from 'next/navigation';
import { useLocalStorage } from '@uidotdev/usehooks';
import { navItems } from '@/components/layout/Navigator';

export default function PageAnimation({
  children,
}: {
  children: React.ReactNode;
}) {
  const [isAnimating, setIsAnimating] = useState(false);
  const [oldPathname, setOldPathname] = useLocalStorage('oldPathname', '');
  const pathname = usePathname();
  const { lng } = useParams();
  const currentNavItemIndex = Object.values(navItems).find(
    (item) => pathname === `/${lng}${item.link}`.replace(/\/$/, ''),
  )?.menuIndex;

  const prevNavItemIndex = Object.values(navItems).find(
    (item) => oldPathname === `/${lng}${item.link}`.replace(/\/$/, ''),
  )?.menuIndex;

  const getInitialX = () => {
    if (currentNavItemIndex === prevNavItemIndex) return;

    if (currentNavItemIndex && prevNavItemIndex) {
      return currentNavItemIndex > prevNavItemIndex ? '100%' : '-100%';
    }
  };

  const getAnimateX = () => {
    return 0;
  };

  useEffect(() => {
    if (oldPathname !== pathname) {
      setOldPathname(pathname);
    }
  }, [pathname]);

  return (
    <AnimatePresence>
      <motion.div
        initial={{ opacity: 0, x: getInitialX() }}
        animate={{ opacity: 1, x: getAnimateX() }}
        transition={{ ease: 'linear', duration: 0.25 }}
        className={`h-full ${isAnimating ? '' : '!transform-none'}`}
        onAnimationStart={() => setIsAnimating(true)}
        onAnimationComplete={() => setIsAnimating(false)}
      >
        {children}
      </motion.div>
    </AnimatePresence>
  );
}
